import NextErrorComponent from 'next/error';
import * as Sentry from '@sentry/node';
import React from "react";

const Error = ({statusCode = null, hasGetInitialPropsRun = null, err = null}) => {
  if (!hasGetInitialPropsRun && err) {
    Sentry.captureException(err);
  }

  return <NextErrorComponent statusCode={statusCode}/>
};

Error.getInitialProps = async ({res, err, asPath}) => {
  //@ts-ignore
  const errorInitialProps = await NextErrorComponent.getInitialProps({res, err});
  //@ts-ignore
  errorInitialProps.hasGetInitialPropsRun = true;

  if (res?.statusCode === 404) {
    return {statusCode: 404};
  }
  if (err) {
    Sentry.captureException(err);
    await Sentry.flush(2000);
    return errorInitialProps;
  }

  Sentry.captureException(
    //@ts-ignore
    new Error(`_error.js getInitialProps missing data at path: ${asPath}`)
  );
  await Sentry.flush(2000);

  return errorInitialProps;
};

export default Error;
